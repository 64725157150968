import React from 'react';
import { graphql } from 'gatsby';

import BlogPosts from 'staticPages/blog/BlogPosts';
import BlogLanding from 'staticPages/blog/BlogLanding';

const BlogPostsLanding = ({ data }) => (
  <BlogLanding>
    <BlogPosts postContent={data?.allContentfulBlogPost?.edges} />;
  </BlogLanding>
);

export default BlogPostsLanding;

export const query = graphql`
  query {
    allContentfulBlogPost {
      edges {
        node {
          ...BlogPostFragment
        }
      }
    }
  }
`;
